import {Component} from "react"
import { css } from '@emotion/react'
import { navigate } from "../../helpers/queryNavigate"

// FINANCIERA COMPONENTS
import { CardElevation,TitleIcon,ButtonDegrade, MaterialInput} from '@findep/mf-landings-core'

//MATERIAL UI COMPONENTS 
import {Backdrop,Typography,Snackbar,Grid,Container} from "@material-ui/core"
import { Alert } from '@material-ui/lab';

//MODULES PROPS SYSTEM
import CONSTANTS from '../../helpers/preOfertaConstants'

//MATERIAL ICONS
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';

//SERVICES METHOD
import { verifyBank } from '../../services/LandingV4/verifyBank'
import { GlobalStateFooterContext } from "../../context/GlobalStateFooterContext"
import getTenant from "../../helpers/getTenant"


 const cmpTxt1= css`      
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);     
 `
 const buttonCss = css`
    display: flex;
    justify-content: flex-end;
`
const bDrop= css`  
    z-index:9999;
    color: '#fff';
  `  
export default class DatosPersonales extends Component {
     constructor(props) {
        super(props);
        this.state = {
            flujo: !((typeof window !== 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '',
            clabe: '',
            dispersion: CONSTANTS.SPEI,
            buttonEnabled: false,
            errorService: false,
            bdHabilita: false,
            errorServiceInfo:'',
            _errors: {
                clabe: {
                    error: false,
                    errorType: 'Numero invalido',
                }
            }
        }
        this._handleChange = this._handleChange.bind(this)
        this._handleError = this._handleError.bind(this)
    }

    static contextType = GlobalStateFooterContext;

    async componentDidMount() {
        //GET TENANT        
        const tenantValue = await getTenant(this.state.flujo.creditApplicationId)
        this.context.setIsDynamic(true)
        this.context.setTenantFooter(tenantValue)
    }

    _handleChange(event,) {
        this._handleError(event.target.name,{}, false)
        this.setState({[event.target.name]: event.target.value})
    }

     _handleError(prop, data, bool = true) {
        let { _errors } = this.state
        _errors[prop] = {
            ..._errors[prop],
            error: bool
        }
        this.setState({_errors})
    }

    componentDidUpdate(_, prevState) {
        const { clabe, _errors} = this.state

        if(clabe && !_errors.clabe.error) {
            if(!prevState.buttonEnabled) {
                this.setState({buttonEnabled: true})
            }
        } else if(prevState.buttonEnabled) {
            this.setState({buttonEnabled: false})
        }
    }

    async submitStep() {

            const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
            let id = flujo?.creditApplicationId

        this.setState({buttonEnabled: false, bdHabilita: true})
        const {dispersion, clabe } = this.state
        const bankVerify = new  verifyBank(id);        
        bankVerify.setDisbursementMethod(dispersion)
        if(dispersion === CONSTANTS.SPEI) {
            bankVerify.setCardNumber(clabe.length === 16 ? clabe : '')
            bankVerify.setClabe(clabe.length !== 16 ? clabe : '')
        }
        try{
            await bankVerify.send()
             let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
             flujo.tubo = {...flujo.tubo, DefineBankAccount: 'InProgress'}                 
             sessionStorage.setItem('flujo', JSON.stringify(flujo))
            navigate('/tu-proceso')
        } catch(e) {            
            this.setState({errorServiceInfo: e.data ? e.data : 'Lo sentimos, por el momento el servicio no se encuentra disponible', errorService: true, bdHabilita: false})
           // alert(`Error al enviar la oferta ${e.message}`)
        } finally {
          //  this.setState({buttonEnabled: true, loading: false})
        }
    }
    
    render(){
        return(
            <Container  justify="center"> 
                <br/>
                <Grid container justify="center">
                    <Grid item style={{textAlign:"center"}}>                                
                        <TitleIcon icon={AttachMoneyIcon } title="Verificación bancaria" textColor />
                     </Grid>
                </Grid>
                <br/>
                <Grid container justify="center">
                <Grid item  justify="center" xs={12} sm={6}>
                <CardElevation>
                    <Grid container spacing={2}>              
                        <Grid item xs={12}>
                             <Typography component='h1' css={cmpTxt1}>
                                {/* Necesitamos verificar de nuevo que tu cuenta pueda recibir dinero de manera electrónica.<br/>
                                Ingresa a continuación los 16 dígitos de tu tarjeta de débito a los 18 dígitos de tu cuenta CABLE: */}
                                Necesitamos verificar de nuevo que tu cuenta pueda recibir dinero de manera electrónica.<br/>
                                Ingresa a continuación los 18 dígitos de tu cuenta CLABE en donde recibes el pago de tú nómina.
                            </Typography>                            
                        </Grid>
                        <Grid item xs={1}>
                            <AttachMoneyIcon/>
                        </Grid>
                        <Grid item xs={11}>
                            <MaterialInput 
                                    id="verificacion-bancaria-clabe"
                                    type="text"
                                    name="clabe"
                                    // disabled={this.state.dispersion !== CONSTANTS.SPEI} 
                                    onError={(error) => this._handleError('clabe', error)}
                                    error={this.state._errors.clabe.error}
                                    pattern="^((?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})|\d{18})$"
                                    accept="0-9"
                                    errorMessage={this.state._errors.clabe.errorType}
                                    onChange={(event, data) => {event.target.value = data.value; this._handleChange(event)}}
                                    fullWidth
                                    transform={(value) => value.slice(0, 18)}
                                    label="No. de cuenta CLABE" 
                                    helperText="Ingresa 18 dígitos" 
                                    variant="outlined"
                                    value={this.state.clabe}
                                />
                        </Grid>
                        <Grid item xs={12} css={buttonCss}>
                            <ButtonDegrade 
                                id="verificacion-bancaria-continuar-button"
                                icon={ArrowForwardIcon}
                                textButton='Continuar' 
                                onClick={()=>this.submitStep()} 
                                disabled={!this.state.buttonEnabled} 
                            />
                        </Grid>
                    </Grid>
                </CardElevation>
                </Grid>
                </Grid>
                 <Backdrop css={bDrop} open={this.state.bdHabilita} >
                        <CircularProgress color="primary" />
                 </Backdrop>
                   <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        {this.state.errorServiceInfo}
                    </Alert>
                </Snackbar>   
            </Container>
        )
    }
}